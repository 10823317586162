import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import abi from '../contracts/abi';
import { ethers } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import * as Constants from '../constants/config';

const contractAddress = Constants.CONTRACT_ADDRESS_GOLD;

const Nft = () => {
  // eslint-disable-next-line
  const [currentAccount, setCurrentAccount] = useState(null);
  const [mintTotal, setMintTotal] = useState(0);
  const [airdropTotalMintSupply, setAirdropTotalMintSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [tokenNewCost, setTokenNewCost] = useState(0);
  const [ownerAddress, setOwnerAddress] = useState(0);
  const [adresstoAirdrop, setAdresstoAirdrop] = useState(null);
  const [qtytoAirdrop, setQtytoAirdrop] = useState(null);
  const [baseUri, setBaseUri] = useState(null);
  const [publicMaxMint, setPublicMaxMint] = useState(0);
  const [newPublicMaxMint, setNewPublicMaxMint] = useState(0);
  const [mode, setMode] = useState(null);
  const [revealStatus, setRevealStatus] = useState(0);
  const [nonRevealUri, setNonRevealUri] = useState(null);
  const history = useNavigate();

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const nftContract = new ethers.Contract(contractAddress, abi, signer);

  useEffect(() => {
    getToken();
    checkWalletIsConnected();
    getConfig()
    document.body.style.backgroundImage = "none";
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getToken = async () => {
    if(localStorage.getItem("valid_account") !== 'verified') {
      history("/");
    }
  }

  const getConfig = async () => {
    try { 
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      setMintTotal((await contract.totalSupply()).toNumber());
      setMaxSupply((await contract.MAX_SUPPLY()).toNumber());
      setContractBalance((await contract.getBalance()).toString() / 1E18);
      setTokenPrice((await contract.PRICE()).toString() / 1E18);
      setRevealStatus(await contract._revealed());
      setAirdropTotalMintSupply((await contract._airdropCounter()).toNumber());
      setOwnerAddress(await contract.owner());
      setMode((await contract.getSaleMode()).toNumber());
      setPublicMaxMint((await contract.maxMintAmount()).toNumber());
    } catch(error) {
      console.log(error)
    }
  }

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error('Make sure you have Metamask installed!')
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      setCurrentAccount(accounts[0]);
    } else {
      console.log("No authorized account found");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error('Please install Metamask!')
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      if(ownerAddress === accounts[0]) {
        setCurrentAccount(accounts[0]);
      } else {
        toast.error('Please connect owner wallet')
      }
      //alert.show("Found an account! Address: ", accounts[0]);
    } catch (err) {
      console.log(err)
    }
  }

  // on off meta data
  const switchRevealHandler = async (e) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        if(revealStatus) {
          let nftTxn = await nftContract.reveal(false);
          toast.loading('Please wait...');
          await nftTxn.wait();
          toast.success('Updated');
        } else {
          let nftTxn = await nftContract.reveal(true);
          toast.loading('Please wait...');
          await nftTxn.wait();
          window.location.reload(false);
          toast.success('Updated');
        }

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const switchModeHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        let nftTxn = await nftContract.setMode(mode);
        toast.loading('Please wait...');
        await nftTxn.wait();
        toast.success('Updated');

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const updateTokenCostHandler = async () => {
    try {
      const { ethereum } = window;

      if (!tokenNewCost || tokenNewCost === '0') {
        toast.error("Please enter a value", {id: "error"});
        return false;
      }
      if (ethereum) {
        let nftTxn = await nftContract.setCost(tokenNewCost);

        toast.loading('Please wait...');
        await nftTxn.wait();
        toast.success('Updated');
        window.location.reload(false);

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const WithdrawHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        let nftTxn = await nftContract.withdraw();

        toast.loading('Please wait...');
        await nftTxn.wait();

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const updateMetaUriHandler = async () => {
    try {
      const { ethereum } = window;

      if (!baseUri || baseUri === '0') {
        toast.error("Please enter wallet address");
        return false;
      }
      if (ethereum) {
        let nftTxn = await nftContract.setBaseURI(baseUri);

        toast.loading('Please wait...');
        await nftTxn.wait();
        toast.success('Updated!');

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const airdropTokenHandler = async () => {
    try {
      const { ethereum } = window;

      if ((!adresstoAirdrop || adresstoAirdrop === '0') && (!qtytoAirdrop || qtytoAirdrop === '0')) {
        toast.error("Please enter wallet address");
        return false;
      }
      if (ethereum) {
        let nftTxn = await nftContract.airDrop(adresstoAirdrop, qtytoAirdrop);

        toast.loading('Please wait...');
        await nftTxn.wait();
        window.location.reload(false);
        toast.success('Sent!');

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const updatePublicMaxMintHandler = async () => {
    try {
      const { ethereum } = window;

      if (!newPublicMaxMint || newPublicMaxMint === '0') {
        toast.error("Please enter a value");
        return false;
      }
      if (ethereum) {
        let nftTxn = await nftContract.setPublicsaleMintAmount(newPublicMaxMint);

        toast.loading('Please wait...');
        await nftTxn.wait();
        window.location.reload(false);
        toast.success('Updated');

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }


  const updateNonRevealUriHandler = async () => {
    try {
      const { ethereum } = window;

      if (!nonRevealUri || nonRevealUri === '0') {
        toast.error("Please enter wallet address");
        return false;
      }
      if (ethereum) {
        let nftTxn = await nftContract.setNotRevealedURI(nonRevealUri);

        toast.loading('Please wait...');
        await nftTxn.wait();
        toast.success('Updated!');

      } else {
        toast.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const connectWalletButton = () => {
    return (
        <button onClick={connectWalletHandler} className='btn btn-danger '>
          Connect Owner Wallet
        </button>
    )
  }

  const updateTokenCost = () => {
    return (
        <button onClick={updateTokenCostHandler} className='btn btn-success'>
          Update
        </button>
    )
  }

  const switchReveal = () => {
    return (
        <button onClick={switchRevealHandler} className='btn btn-success'>
          Reveal Metadata
        </button>
    )
  }

  const updateNonRevealUri = () => {
    return (
        <button onClick={updateNonRevealUriHandler} className='btn btn-success'>
          Update
        </button>
    )
  }

  const airdropToken = () => {
    return (
        <button onClick={airdropTokenHandler} className='btn btn-success'>
          Send
        </button>
    )
  }

  const updatePublicSaleMaxMint = () => {
    return (
        <button onClick={updatePublicMaxMintHandler} className='btn btn-success'>
          Update
        </button>
    )
  }

  const Withdraw = () => {
    return (
        <button onClick={WithdrawHandler} className='btn btn-danger'>
          Withdraw <span className="badge">{contractBalance} ETH</span>
        </button>
    )
  }

  const updateUri = () => {
    return (
        <button onClick={updateMetaUriHandler} className='btn btn-success'>
          Update
        </button>
    )
  }

  return (
      <div className="container mt-5 admin-bg-bolor">
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#0982e1',
                color: '#fff',
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
        />
        {localStorage.getItem("valid_account") === 'verified' ? '' :
            <div className="buttons">{ connectWalletButton() } </div>
        }
    

        <div className="row">
          <div className="col-md-12">
            <div className="list-group ">
              <a href="/#" className="list-group-item" rel="noreferrer">
                  VIP PASS
              </a>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-md-6">
            <div className="list-group">
              <a href="/#" className="list-group-item disabled" rel="noreferrer">
                  Token Summary
              </a>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Total Max Supply
                      <span className="badge badge-primary badge-pill">{maxSupply}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                    Token Cost
                      <span className="badge badge-primary badge-pill">{tokenPrice} ETH</span>
                    </li>
                  </ul>
            </div>
            </div>
            <div className="col-md-6">
            <div className="list-group">
              <a href="/#" className="list-group-item disabled" rel="noreferrer" >
                  Minted Summary
              </a>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Total Supply
                    <span className="badge badge-primary badge-pill">{mintTotal}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                  Airdrop Supply
                    <span className="badge badge-primary badge-pill">{airdropTotalMintSupply}</span>
                  </li>
                </ul>
                </div>
            </div>
        </div>
        
        <div className="row">
            <div className="col-md-6">
            <div className="alert alert-success">
            {localStorage.getItem("valid_account") === 'verified' ? Withdraw() : ''}
            </div>
            </div>
        </div>
        
        <div className="row">
            <div className="col-md-8">
              <div className="panel panel-default">
                <p className="panel-heading">
                  Update Sale Mode
                </p>
                <div className="panel-body">
                  <div className="row">
                      <div className="radio col-sm-1" style={{'marginTop': '-5px'}} >
                      </div>
                      <div className="radio col-sm-3">
                          <label><input type="radio" name="optradio" onChange={(e) => { setMode(1) }} checked={mode === 1} />Public Sale</label>
                      </div>
                      <div className="radio col-sm-4">
                          <label><input type="radio" name="optradio" onChange={(e) => { setMode(0) }} checked={mode === 0} />Pause Contract</label>
                      </div>
                      <button onClick={(e) => { switchModeHandler() }} className='btn btn-success'>
                        Update
                      </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
                { revealStatus ? '' :
                    <div className="panel panel-default">
                      <p className="panel-heading">
                        Reveal Metadata
                      </p>
                      <div className="panel-body">
                        {currentAccount ? switchReveal() : ''}
                      </div>
                    </div>}
            </div>
          </div>

        <div className="row">
            <div className="col-md-12">
          <div className="panel panel-default">
            <p className="panel-heading">
              Update Token Cost<span className="label label-warning">Add the amount in Wei <a href='https://eth-converter.com/' rel='noreferrer' target='_blank'> https://eth-converter.com </a> </span>
            </p>
            <div className="panel-body">
              <div className="field">
                <label>Value: <span className="label label-primary">{tokenPrice}</span></label>
                <div className="control">
                  <input className="form-control" type="text" onChange={e =>setTokenNewCost(e.target.value)}/>
                </div>
              </div>
            </div>
              <div className="panel-footer">{localStorage.getItem("valid_account") === 'verified' ? updateTokenCost() : ''}</div>
          </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                  <p className="panel-heading">
                    Airdrop
                  </p>
                  <div className="panel-body">
                    <div className="col-md-6">
                      <div className="field">
                        <label>Wallet Address </label>
                        <div className="control">
                            <input className="form-control" type="text" onChange={e =>setAdresstoAirdrop(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field">
                        <label>QTY </label>
                        <div className="control">
                          <input className="form-control" type="number" min="1" onChange={e =>setQtytoAirdrop(e.target.value)} />
                        </div>
                      </div>
                    </div>             
                  </div>
                  <div className="panel-footer">{localStorage.getItem("valid_account") === 'verified' ? airdropToken() : ''}</div>
                </div>
              </div>
            </div>

          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-default">
                <p className="panel-heading">
                  Update Max Mint Limit 
                </p>
                <div className="panel-body">
                  <div className="form-group">
                    <label className="">Value: <span className="label label-primary">{publicMaxMint}</span></label>
                    <div className="control">
                      <input className="form-control" type="number" min="1" onChange={e =>setNewPublicMaxMint(e.target.value)}/>
                    </div>
                  </div>
                </div>
                <div className="panel-footer">{localStorage.getItem("valid_account") === 'verified' ? updatePublicSaleMaxMint() : ''}</div>
              </div>
            </div>
              <div className="col-md-6">
                  <div className="panel panel-default">
                    <p className="panel-heading">
                      Update Non Reveal URI
                    </p>
                    <div className="panel-body">
                        <div className="control">
                          <input className="form-control" type="text" onChange={e =>setNonRevealUri(e.target.value)}/>
                        </div>
                    </div>
                        <div className="panel-footer">{currentAccount ? updateNonRevealUri() : ''}</div>
                  </div>
              </div>
            </div>
          <div className="row">
              <div className="col-md-6">
                  <div className="panel panel-default">
                    <p className="panel-heading">
                      Update Base URI
                    </p>
                    <div className="panel-body">
                        <div className="control">
                          <input className="form-control" type="text" onChange={e =>setBaseUri(e.target.value)}/>
                        </div>
                    </div>
                        <div className="panel-footer">{localStorage.getItem("valid_account") === 'verified' ? updateUri() : ''}</div>
                  </div>
              </div>
          </div>
        </div>
        </div>
      </div>
  )
}

export default Nft