import { Toaster } from 'react-hot-toast';
import { useState } from 'react';
import gold_pass_gif from '../img/360_gif_gold.gif';
import twitter_logo from '../img/twitter_logo.png';
import discord_logo from '../img/discord_logo.png';
import instagram_logo from '../img/instagram_logo.png';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
// eslint-disable-next-line
import Faq from "./Faq";
import Mint from "./Mint";
import GoldMint from "./GoldMint";
const networkChainId = 1;
const wrongChainErrorMessage = "You need to change network to Ethereum Mainnet";
const rpcUrls = "https://mainnet.infura.io/v3/";

const Home = () => {
    // eslint-disable-next-line
    const [currentAccount, setCurrentAccount] = useState(null);
    
    const connectWalletHandler = async () => {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const { chainId } = await provider.getNetwork();
    
        if (!ethereum) {
            toast.error('Please install Metamask!', {id: "toast"})
        }
    
        try {
    
            if(chainId !== networkChainId) {
            toast((t) => (
                <span>
                {wrongChainErrorMessage}
                <button onClick={onClickConnect} className="btn wallet-connect">
                    Change
                </button>
                </span>
            ));
            } else {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            setCurrentAccount(accounts[0]);
            localStorage.setItem("wallet_address", accounts[0]);
            window.location.reload(false);
            }
        } catch (err) {
            console.log(err)
        }
    }
    
    const onClickConnect = async () => {
        const { ethereum } = window;
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x'+networkChainId}],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x'+networkChainId,
                    chainName: 'ETH',
                    rpcUrls: [rpcUrls],
                  },
                ],
              });
              window.location.reload(false);
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
    };  

    const Logout = async () => {
    localStorage.removeItem("wallet_address");
    window.location.reload(false);
    }

  return (
      <div> 
          
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
                maxWidth: 600,
                marginTop:26
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
        />

        <section id="nav" className="nav-container">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 text-left">
                        <h3 className="gold-text" data-heading="Alpha Signals">Alpha Signals</h3>
                    </div>
                    <div className="col-sm-6 text-right">
                        { localStorage.getItem("wallet_address") ?
                            <a onClick={Logout} href="/#" rel="noreferrer">Disconnect</a>
                        :
                            <a onClick={connectWalletHandler} href="/#" rel="noreferrer">Connect</a>
                        }
                    </div>
                </div>
            </div>
        </section>

        <section id="home" className="header-container">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <img src={gold_pass_gif} alt="cover"/>
                        <p className="small">Private members group for the profit motivated trader.</p>
                        <p className="">“Always Take Profits”</p>
                    </div>
                </div>
            </div>
            <section id="about" className="about-container">
                <div className="container-fluid">
                    <div className="row">
                        <h3 className="gold-text">What is The Alpha Signal Pass?</h3>
                        <div className="about-box">
                            <div className="col-sm-12 " data-aos="zoom-in" data-aos-duration="1000">
                                <p className="">Our Alpha Signals NFT gives you lifetime membership to our Alpha Club. The core aim of Alpha Signals is to bring together the most profit focused traders in the Crypto world. Our motto is "Always Take Profits". Trading is not about the adrenaline rush, the profits is the rush. We win, you win, everybody wins.</p>
                                <p className="">Our alpha group will lift you into the top 1% of the crypto world. Sharing and divulging our trades as they happen along with timely information so that everyone gains a supreme advantage over the masses of rookie crypto traders.</p>
                                <p className="">Our group is ready to welcome you today whether newbie or the seasoned vet, we are primed to help you quickly grow into an elite Crypto investor. Together we are stronger.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <div id="particles-js" className="particles-js"></div>

        <section id="minting" className="mint-container">
            <div className="container title-container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className="gold-text" data-heading="Lorem ipsum dolor sit amet">Alpha Signals Membership</h3>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row left-right">
                    <Mint />
                    <GoldMint />
                </div>
            </div>

            <div className="container join-club-container">
                <div className="row">
                    <div className="col-sm-12">
                        <a href="#123" rel="noreferrer">Join Alpha Signals</a>
                        <p>You need an Alpha Signals Pass to join our discord!</p>
                    </div>
                </div>
            </div>
        </section>

        <Faq />

        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6 col-xs-12 text-center social-links">
                        <ul className="list-inline links">
                            <li>
                                <a href="https://twitter.com/@AlphaSignals1" target="_blank" className="btn" rel="noreferrer">
                                    <img src={twitter_logo} alt="twitter"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://discord.gg/upQzmpwTwJ" target="_blank" className="btn" rel="noreferrer">
                                    <img src={discord_logo} alt="discord"/>
                                </a>
                            </li>
                            <li>
                                <a href="#123" target="_blank" className="btn" rel="noreferrer">
                                    <img src={instagram_logo} alt="discord"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-xs-12 rights">
                        <p className="rights">Alpha Signals 2022 © All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>

      </div>
  )
}
export default Home;