const Faq = () => {
    return (
    <section id="faq" className="faq-container">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="title-container">
                        <h1 className="gold-text" data-aos="zoom-in" data-aos-duration="500">FAQ</h1>
                    </div>
                </div>
                <div className="col-md-8 col-md-offset-2 col-sm-12 text-left">
                    <div className="panel-group" id="faqCollapse" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default" data-aos="zoom-in" data-aos-duration="500">
                            <div className="panel-heading" role="tab" id="heading1">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapse1" aria-expanded="true" aria-controls="collapse1" className="caaollapsed">
                                    What is Alpha Signals Mission<span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse1" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="heading1">
                                <div className="panel-body">
                                    <p>To share our success in this bear market, giving our best trades in an easy to use format. We provide the price we buy at, the price we set our stop loss at and our targets. Simples.</p>
                                    <p>Whether your the newest newbie or the most experienced trader you can bank big in this bear market.</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default" data-aos="zoom-in" data-aos-duration="500">
                            <div className="panel-heading" role="tab" id="heading2">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                    What is the Utility of Alpha Signals<span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                                <div className="panel-body">
                                    <p>The team behind Alpha is dedicated to providing the best and simplest information and to create value for the community. We are combining membership, merchandise & success and real world utility. We’ll be leaking our plans for the future in the coming weeks. It's important to us to under-promise and over-deliver. But trust us… This is only the beginning.</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default" data-aos="zoom-in" data-aos-duration="500">
                            <div className="panel-heading" role="tab" id="heading3">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                    What are the minting proceeds being used for?<span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
                                <div className="panel-body">
                                    <p>We are deeply focused on investing in our roadmap, community and mission. Up to 5% can be donated to philanthropic organizations in partnership with our community. Remaining funding will be used to enhance our community experience and cover our costs to continue providing the best service to you!</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default" data-aos="zoom-in" data-aos-duration="500">
                            <div className="panel-heading" role="tab" id="heading4">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapse4" aria-expanded="true" aria-controls="collapse4">
                                    We want you to always take profits monetarily and mentally <span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading4">
                                <div className="panel-body">
                                    <p>We are committed to our community, the longevity of trading. It is super important for us to make sure that our users to not experience FOMO and burn out so we will try to provide resources to ensure your mental wellbeing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="polygon-globe-container">
        </div>
    </section>
    )}
  export default Faq;