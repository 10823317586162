import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const history = useNavigate();
  
  const Logout = async () => {
    localStorage.removeItem("wallet_address")
    localStorage.setItem("valid_account", 'not-verified');
    history("/");
  }

  return (
      <nav className="navbar navbar-default" role="navigation" aria-label="main navigation">
        <div className="container-fluid">
          <div className="navbar-brand">

            <a href="/" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <ul className="nav navbar-nav navbar-right">
              <li>
                <button onClick={Logout} className="btn btn-default">Logout</button>
              </li>
          </ul>
        </div>
      </nav>
  )
}

export default Navbar