import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import abi from '../contracts/abi';
import { ethers } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import * as Constants from '../constants/config';

const contractAddress = Constants.CONTRACT_ADDRESS;

const SilverLogin = () => {
  // eslint-disable-next-line
  const [currentAccount, setCurrentAccount] = useState(null);
  const history = useNavigate();

    // eslint-disable-next-line
  const [ownerAddress, setOwnerAddress] = useState(0);

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    checkWalletIsConnected();
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error('Please install Metamask!')
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        localStorage.setItem("wallet_address", accounts[0]);
        setCurrentAccount(accounts[0]);
        checkVerification();
    } catch (err) {
      console.log(err)
    }
  }

  const getConfig = async () => {
    try { 
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      setOwnerAddress(await contract.owner());
      localStorage.setItem("owner_address", await contract.owner());
    } catch(error) {
      console.log(error)
    }
  }

  const checkVerification = async() => {
    if((localStorage.getItem("wallet_address")).toUpperCase() === (localStorage.getItem("owner_address").toUpperCase())) {
      localStorage.setItem("valid_account_silver", 'verified');
      history("/silver-mint-dashboard");
    } else {
       toast.error('Please connect owner wallet')
    }
  }

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error('Make sure you have Metamask installed!')
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      setCurrentAccount(accounts[0]);
    } else {
      console.log("No authorized account found");
    }
  }

  return (
      <div className="container">
      <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
              background: '#0982e1',
              color: '#fff',
            },
            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: 'green',
                secondary: 'black',
              },
            },
          }}
      />
        <div className="vertical-center">
            <button onClick={connectWalletHandler} className="btn connect-wallet-button silver-btn">Login</button>
        </div>
      </div>
  )
}

export default SilverLogin